<template>
    <promo-landing-page
        image-path-in-assets="images/pages/promo/balanceTransfer.svg"
        title="Forget High APRs- Save by Balance Transfer"
        message="Easily transfer your high APR balances to Aven for $0 fee and save on monthly payments."
        :cta-text="$t('global.cta.continue')"
        :on-cta-click="onStartBalanceTransfer"
        :on-dismiss-click="onClose"
    />
</template>

<script>
    import PromoLandingPage from '@/views/promo/PromoLandingPage'

    export default {
        name: 'BalanceTransfer',
        components: {
            'promo-landing-page': PromoLandingPage,
        },
        methods: {
            onClose: function () {
                this.$router.replace('/card')
            },
            onStartBalanceTransfer: function () {
                this.$router.replace('/balanceTransfer')
            },
        },
    }
</script>
